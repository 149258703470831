td ul li .nav-link {
  padding-top: 2px;
  padding-bottom: 2px;
}

label {
  margin-top: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.sidebar {
  background-color: #0a2942;
}

.sidebar .nav-link.active {
  background-color: #016583;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #f7cc03;
}

.c-sidebar {
  font-size: 12px;
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #f7cc03;
}

.sidebar .nav-link.active .nav-icon {
  color: #f7cc03;
}

.nav-item {
  padding-right: 3px;
}

.react-datetime-picker__wrapper {
  border: none !important;
}

.react-datetime-picker__clear-button__icon {
  stroke: #888;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__clear-button__icon {
  stroke: #888;
}

@media print {
  .table-impressao {
    margin: 3em;
  }
}

.css-g1d714-ValueContainer {
  max-height: 100px;
  overflow-y: auto !important;
}

.toast {
  z-index: 1001;
}

.c-legacy-theme a {
  color: #23282c;
}

.c-legacy-theme .c-sidebar {
  color: #fff;
  background: #0a2942;
}

.toast-header {
  padding-bottom: 0 !important;
}

.c-legacy-theme .alert-danger {
  color: #813842;
  background-color: #fee2e1 !important;
  border-color: #fdd6d6 !important;
}

.c-legacy-theme .toast-header.alert-danger {
  border: 0px;
  font-weight: bold;
}

.react-dropdown-tree {
  display: block;
  position: absolute;
  background: #fff;
  z-index: 999;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
  text-align: right;
  white-space: nowrap;
}

.modal-header {
  background-color: #0a2942;
  color: #ffffff;
}

.modal-title {
  color: #ffffff;
}

button[aria-label="Close"] span {
  color: #f7cc03;
}

.c-header-nav .react-dropdown-tree {
  right: 30px;
  top: -18px;
}

.modal-body .react-dropdown-tree {
  position: initial !important;
  margin-bottom: -13px;
}

.modal-body .react-dropdown-tree .dropdown-trigger {
  display: none;
}

.action {
  cursor: pointer;
  display: table-cell;
  padding-left: 4px;
  float: right;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;
}

.action:hover {
  color: #016583;
}

.toggle {
  font-style: normal;
  margin: 0 5px 0 10px;
  font-size: 17px;
}

.node {
  padding: 0;
}

.node:nth-of-type(even) {
  background-color: #fff;
}

.node:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.toggle.expanded:after {
  content: "\25BE";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.toggle.collapsed:after {
  content: "\25B8";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.tag-remove {
  display: none !important;
}

.node.disabled>* {
  cursor: pointer !important;
  color: inherit !important;
}

.dropdown-content {
  text-align: left;
}

.dropdown-content .root {
  padding-inline-start: 0px;
}

@page {
  size: auto;
}

.select_company_group {
  min-width: 250px;
}

.table svg {
  margin-right: 5px;
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link {
  padding-left: 25px;
}

.c-sidebar-nav-dropdown.c-show>.c-sidebar-nav-dropdown-items {
  max-height: min-content !important;
}

button svg {
  margin-top: -3px;
  margin-right: 3px;
}

.mgr-5 {
  margin-right: 5px;
}

.c-main {
  padding-top: 5px;
  padding-bottom: 5px;
}

.c-main .container-fluid {
  padding-left: 5px;
  padding-right: 5px;
}

Button {
  margin-left: 5px;
}

.bg-danger-gradient {
  background-color: var(--cui-danger, #d93737);
  color: #fff;
  margin-left: 25px;
  margin-top: 5px;
}

.card {
  margin-bottom: 5px;
}

.configuracao-tela-icon {
  font-size: 20px;
  color: #000015;
  opacity: 0.5;
  margin-right: 1rem;
}

.modal-xxl {
  max-width: 1800px;
  height: -webkit-fill-available;
}

.configuracao-tela-icon:hover {
  color: #23282c;
  opacity: 1;
}

.modal-xxl .modal-content {
  height: 100%;
}

.modal-full-width-min-content-height {
  max-width: 1000px;
  height: -webkit-fill-available;
}

.modal-full-width-min-content-height .modal-content {
  height: fit-content;
}

.tabela-simples thead tr th,
.tabela-simples tbody tr td {
  border: 1px solid #c8ced3;
}

.modal-header button.close {
  color: #f7cc03;
}

.modal-header button.close:hover {
  color: #f7cc03;
}

.zindex-1051 {
  z-index: 1051;
}

.row {
  line-height: normal;
}

.table th,
.table td {
  padding: 0.5rem;
  font-size: 12px;
}

.card-body {
  padding: 0.8rem;
}

label {
  margin-top: 4px;
  font-size: 12px;
}

.c-subheader {
  height: 20px;
  font-size: 12px;
}

.c-subheader-nav {
  min-height: 20px;
}

.form-group {
  margin-bottom: 0.5rem;
}

element.style {}

.form-control:focus {
  color: #5c6873;
  background-color: #fff;
  border-color: #8ad4ee;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
}

.form-control {
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
}

.btn {
  font-size: 12px;
}

.nav-link {
  font-size: 12px;
}

b,
a,
text,
strong {
  font-size: 12px;
}

.verticalMiddle {
  td {
    vertical-align: middle;
    height: 65px;
    overflow: hidden;
  }
}

.container-botoes-painel-log {
  width: 100%;
  padding: 0;
  margin: 0 7px 1rem 15px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-botoes-painel-log-esquerdo {
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  gap: .5rem;
}

.container-botoes-painel-log-direito {
  width: 100%;
  margin-right: .5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: .5rem;
}

.container-botoes-painel-log-direito button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
}

.container-botoes-painel-log-direito button svg {
  height: 12px;
  width: 12px;
  margin: 0;
  padding: 0;
}

.spin-loader {
  width: 10px;
  height: 10px;
  border: 1px solid;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: spininin 1s linear infinite;
}

@keyframes spininin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin-loader .preto {
  border-color: #000000;
}

.spin-loader .branco {
  border-color: #FFFFFF;
}

.tag-cancelada {
  height: 32px;
  padding: 6px 12px;
  background-color: #FF0000;
  border-radius: .25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border: none;
}

.tag-cancelada span {
  color: #FFFFFF;
  font-size: 12px;
}

.botao-icone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
}

.botao-icone svg {
  margin: 0;
}

.leaflet-control {
  padding: 0 !important;
}

.leaflet-routing-geocoders input {
  padding: .1rem .2rem;
}

.leaflet-routing-add-waypoint {
  height: 22.5px;
  width: 22.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaflet-routing-add-waypoint:after {
  display: block;
  height: 25px !important;
  width: 22px !important;
}

.tabela-equipamentos>td {
  border: 1px solid #e4e7ea;
}

.card-header-logistico {
  padding-top: 8px;
  padding-bottom: 8px;
}

.span-progress {
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.svg-15 {
  width: 15px;
  height: 15px;
}

.svg-20 {
  width: 20px;
  height: 20px;
}

.flex,
.flex-row,
.flex-column,
.align-center,
.justify-center,
.justify-start,
.justify-end,
.justify-between {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.botao-padrao {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  gap: .25rem;
}

.botao-padrao svg {
  margin: 0;
}

.mr-1 {
  margin-right: 1rem;
}

.ml-05 {
  margin-left: .5rem;
}

.ml-025 {
  margin-left: .25rem;
}

.mr-05 {
  margin-right: .5rem;
}

.mr-025 {
  margin-right: .25rem;
}

.m-025 {
  margin: .25rem;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mw-max-content {
  min-width: max-content;
}

.mw-min-content {
  min-width: min-content;
}

.w-max-content {
  width: max-content;
}

.w-min-content {
  width: min-content;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.max-h-inherit {
  max-height: inherit;
}

.max-w-inherit {
  max-width: inherit;
}

.container-graficos-detalhes {
  max-width: 99%;
  max-height: 99%;
  height: 100%;
  width: 100%;
}

.bg-a52 {
  background-image: url('../assets/img/brand/logo1.png');
}

.bg-branco-05 {
  background-color: rgba(255, 255, 255, 0.9);
}

.text-align-center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.cabecalho-painel-indicadores {
  position: sticky;
  top: 56px;
  z-index: 1;
  background-color: #e4e5e6;
}

.text-decoration-none {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.color-red {
  color: red;
}

.loader-button {
  width: 18px;
  height: 18px;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mensagem-sucesso {
  background-color: #4dbd74;
  color: #FFFFFF;
  font-size: 12px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: .25rem;
}

.list-style-none {
  list-style: none;
}
